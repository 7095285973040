<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <div>
        <h1 class="tex-gray-800 text-2xl mt-6">Notificaciones:</h1>
      </div>
      <div class="flex items-center bg-gray-100 border rounded-full p-2">
        <button class="flex" @click="markAsRead">
          <div class="mr-2">Marcar todas como leidas</div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>

    <div>
      <div
        v-for="(notification, index) in notifications"
        class="border border-gray-300 rounded-2xl shadow mb-4 flex items-center h-24"
        :key="notification.id"
      >
        <div class="flex items-center justify-center mr-6 h-full w-16  bg-gray-900 text-white font-bold text-sm rounded-l-2xl" >
          {{ index + 1}}
        </div>

        <div
          v-if="notification.data.type == 'redeemable_out_of_stock'"
          class="flex-1 flex p-4 items-center justify-between"
        >

          <div class="flex w-1/5  justify-center">
            <div class="bg-green-300 px-4 py-2 rounded-full font-bold text-sm">
              Sin Leer
            </div>
          </div>

          <div class="w-1/5  ml-6  flex flex-col justify-center">
              <div class="font-bold tex-center">
                {{ notification.data.display_name }}
              </div>
              <div class="text-sm ">
                {{  new Date(notification.created_at).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
              }) }}
              </div>

            <div class="w-36 text-center text-xs bg-red-400 text-white px-4 pt-2 pb-2 rounded-full ">
              Producto sin stock
            </div>
          </div>

          <div class="w-2/5">

          </div>
          <div class="flex w-1/5 bg-blue-500 rounded-full px-4 py-2 justify-center">
            <div class="">
              <router-link to="/cart" class="text-white font-bold text-sm">
                Ver Producto
              </router-link>
            </div>

          </div>
        </div>

        <div

          v-if="notification.data.type == 'redeem_complete'"
          class="flex-1 p-4 flex items-center justify-between"
        >
          <div class="flex w-1/5  justify-center">
            <div class=" bg-green-300 px-4 py-2 rounded-2xl font-bold text-sm">
              Sin Leer
            </div>
          </div>
          <div class="w-1/ ">
            <div class="font-bold">Nueva canje Procesado</div>
            <div class="text-sm">
              Codigo de compra:
              <strong>{{ notification.data.redeem_code }}</strong>
              <div>
                {{  new Date(notification.created_at).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
              }) }}
              </div>
            </div>
          </div>

          <div class="w-1/5 bg-blue-500 rounded-full px-4 py-2 text-center">
            <router-link
              :to="'/redesem/' + notification.data.redeem_id"
              class="text-white font-bold text-sm"
            >
              Ver Canje
            </router-link>
          </div>

          <div class="w-1/5 bg-gray-100 rounded-full px-4 py-2 hidden">
            <button>Marcar como leido</button>
          </div>

          <div class="w-1/5 ">
            <div class="font-bold text-center">Número de rastreo:</div>
            <div class="flex items-center justify-center text-sm">
              <div class="mr-2 text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                  />
                </svg>
              </div>
              <div>{{ notification.data.tracking_number }}</div>
            </div>
          </div>
          <div class="w-1/5 bg-orange-500 rounded-full px-4 py-2 text-center">
            <a
              href="https://forzadelivery.com/rastreo/"
              target="_new"
              class="text-white font-bold text-sm"
            >
              Reastrear en FORZA
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    async getNotifications() {
      try {
        const { data } = await this.axios.get("/notifications/all");
        this.notifications = data.notifications;
      } catch (error) {
        console.log(error);
      }
    },

    async markAsRead() {
      try {
        const { data } = await this.axios.get("/notifications/mark-as-read");
        this.notifications = data.notifications;
        window.location.href = "/";
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getNotifications();
  },
};
</script>
